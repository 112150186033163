import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import moment from "moment";
import { GlobalContext } from "../context/GlobalContext";
import { useSocket } from "../context/SocketContext";
import SiteHeader from "./siteHeader";
import Chat from "./Chat";
import { ProcessSteps } from "./ProcessSteps";

const FormsDynamicTable = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const { globalUserOrganizationAccessName } = useContext(GlobalContext);
  const navigate = useNavigate();
  const { socket, isConnected } = useSocket(); // Destructure socket and connection helper
  const [showChat, setShowChat] = useState(false);
  const [activeFilter, setActiveFilter] = useState("all");

  const applyFilters = (items, searchTerm = "", filterType = activeFilter) => {
    let filtered = [...items];

    if (searchTerm) {
      filtered = filtered.filter((item) =>
        item.loan_name?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (filterType === "pending") {
      filtered = filtered.filter((item) => {
        const step = steps.find((s) => s.id === item.progress_step);
        return step?.pending;
      });
    }
    // Add other filter cases here as needed

    return filtered;
  };

  useEffect(() => {
    let isMounted = true; // Track component's mounted state to avoid setting state on unmounted components

    const fetchData = async () => {
      try {
        console.log(
          "[FormsDynamicTable] WebSocket connected. Fetching data..."
        );

        // Emit the get_items event
        socket.emit("get_items", {
          fields: [
            "_id",
            "loan_name",
            "progress_step",
            "last_updated",
            "date_created",
          ],
        });

        // Register event listeners only if the component is still mounted
        if (isMounted) {
          socket.on("items", (items) => {
            console.log("[FormsDynamicTable] Received items:", items);
            const reversedItems = items.reverse();
            setData(reversedItems);
            setFilteredData(applyFilters(reversedItems));
          });

          socket.on("new_item", (item) => {
            console.log("[FormsDynamicTable] New item received:", item);
            setData((prevData) => [item, ...prevData]);
            setFilteredData((prevData) => [item, ...prevData]);
          });

          socket.on("update_item", (updatedItem) => {
            console.log("[FormsDynamicTable] Item updated:", updatedItem);
            setData((prevData) =>
              prevData.map((item) =>
                item._id === updatedItem._id ? updatedItem : item
              )
            );
            setFilteredData((prevData) =>
              prevData.map((item) =>
                item._id === updatedItem._id ? updatedItem : item
              )
            );
          });

          socket.on("delete_item", (id) => {
            console.log(`[FormsDynamicTable] Item deleted with id: ${id}`);
            setData((prevData) => prevData.filter((item) => item._id !== id));
            setFilteredData((prevData) =>
              prevData.filter((item) => item._id !== id)
            );
          });
        }
      } catch (error) {
        console.error(
          "[FormsDynamicTable] Error during WebSocket connection:",
          error
        );
      }
    };

    if (isConnected) {
      fetchData(); // Initiate data fetching
    }

    // Cleanup listeners and avoid setting state if the component unmounts
    return () => {
      console.log("[FormsDynamicTable] Cleaning up listeners.");
      isMounted = false;
      socket?.off("items");
      socket?.off("new_item");
      socket?.off("update_item");
      socket?.off("delete_item");
    };
  }, [isConnected, socket]); // Empty dependency array to run once on component mount

  // Step mappings
  const steps = ProcessSteps;
  const customStyles = {
    headRow: {
      style: {
        borderRadius: "4px", // Add border radius to the header bar
        backgroundColor: "#e2e4e7", // Optional: set a background color for the header
        // padding: '10px', // Optional: add some padding to the header cells
      },
    },
    headCells: {
      style: {
        fontWeight: "bold", // Optional: make the header text bold
      },
    },

    rows: {
      style: {
        paddingTop: "24px", // Top padding
        paddingBottom: "24px", // Bottom padding
        borderRadius: "4px",
      },
    },
    // headCells: {
    //     style: {
    //         backgroundColor: '#e2e4e7',

    //     },
    // },
  };

  // Table columns definition
  const columns = [
    {
      name: <span className="text-[#363C45] text-[12px]">Loan Name</span>,
      selector: (row) => row.loan_name || "No Loan Name",
      sortable: true,
      cell: (row) => (
        <span
          className="text-[22px] text-[#363C45] font-[500] leading-[24px] cursor-pointer"
          onClick={() => handleRowClick(row)} // Row click now triggers only on the Loan Name
        >
          {row.loan_name || "No Loan Name"}
        </span>
      ),
      width: "17%",
    },
    {
      name: "Current Step Status",
      selector: (row) => {
        const currentStep = steps.find((step) => step.id === row.progress_step);
        return currentStep ? currentStep.title : "Unknown Status";
      },
      sortable: true,
      cell: (row) => {
        const currentStep = steps.find((step) => step.id === row.progress_step);
        return (
          <div className="w-full">
            <span className="className='text-[#363C45] font-[500] text-[15px]'">
              {currentStep ? currentStep.title : "Unknown Status"}
            </span>
            <ProgressBar currentStep={row.progress_step} steps={steps} />
          </div>
        );
      },
      width: "32%",
    },
    {
      name: "Next Step",
      selector: (row) => {
        const nextStep = steps.find(
          (step) => step.id === row.progress_step + 1
        );
        return nextStep ? nextStep.title : "Final Step";
      },
      sortable: true,
      cell: (row) => {
        const nextStep = steps.find(
          (step) => step.id === row.progress_step + 1
        );
        return (
          <span className="className='text-[#363C45] font-[500] text-[15px]'">
            {nextStep ? nextStep.title : "Final Step"}
          </span>
        );
      },
      width: "25%",
    },
    {
      name: "Last Activity",
      selector: (row) => moment(row.last_updated).fromNow(),
      sortable: true,
      cell: (row) => (
        <span className="text-[14px] text-[#535C69] font-[400] leading-[24px]">
          {moment(row.last_updated).fromNow()}
        </span>
      ),
      width: "10%",
    },
    {
      name: "Created",
      selector: (row) => moment(row.date_created).format("MMM D, YYYY"),
      sortable: true,
      cell: (row) => (
        <span className="text-[14px] text-[#535C69] font-[400] leading-[24px]">
          {moment(row.date_created).format("MMM D, YYYY")}
        </span>
      ),
      width: "10%",
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="ms-2">
          <button
            className="text-red-600 hover:text-red-800 transition flex items-center justify-center"
            onClick={(event) => {
              event.stopPropagation(); // Prevent navigation on delete click
              handleDelete(row._id); // Call the delete function
            }}
          >
            <img src="/images/Trash.svg" width={16} height={16} alt="Delete" />
          </button>
        </div>
      ),
      width: "6%",
    },
  ];

  const handleRowClick = (row) => {
    console.log("[FormsDynamicTable] Row clicked:", row);
    navigate(`/individual-loan-tracker?id=${row._id}`);
  };

  const addItem = () => {
    const loan_name = prompt("Enter loan name:");
    if (loan_name) {
      console.log(`[FormsDynamicTable] Adding new loan: ${loan_name}`);
      console.log(
        "[FormsDynamicTable] Emitting add_item event with loan_name:",
        loan_name,
        "and user_organization_access_name:",
        globalUserOrganizationAccessName
      );
      
      // Add callback to confirm the event was sent
      socket.emit("add_item", {
        loan_name: loan_name,
        user_organization_access_name: globalUserOrganizationAccessName,
      }, (response) => {
        console.log("[FormsDynamicTable] Server response for add_item:", response);
        if (!response || response.error) {
          console.error("[FormsDynamicTable] Error adding item:", response?.error);
          // Fallback: force a refresh if the socket update fails
          socket.emit("get_items", {
            fields: ["_id", "loan_name", "progress_step", "last_updated", "date_created"],
          });
        }
      });
    } else {
      console.log(
        "[FormsDynamicTable] Add item cancelled: No loan name provided"
      );
    }
  };

  const handleDelete = (id) => {
    console.log(`[FormsDynamicTable] Deleting loan with id: ${id}`);
    console.log("[FormsDynamicTable] Emitting delete_item event with id:", id);
    socket.emit("delete_item", id);
  };

  const searchLoans = (e) => {
    const input = e.target.value.toLowerCase();
    console.log("[FormsDynamicTable] Searching loans with input:", input);
    setFilteredData(applyFilters(data, input));
    if (input) {
      const filtered = data.filter((item) =>
        item.loan_name?.toLowerCase().includes(input)
      );
      console.log("[FormsDynamicTable] Filtered data:", filtered);
      setFilteredData(filtered);
    } else {
      console.log("[FormsDynamicTable] Resetting filtered data to full data");
      setFilteredData(data);
    }
  };

  const handleFilterClick = (filterType) => {
    setActiveFilter(filterType);
    setFilteredData(applyFilters(data, "", filterType));
    if (activeFilter === filterType) {
      setActiveFilter("all");
      setFilteredData(applyFilters(data, "", "all"));
    }
  };

  return (
    <div>
      <SiteHeader awayFromHome={false} />
      <div className="p-6 bg-gray-100 min-h-screen pt-[2.25rem]">
        <div>
          <div className="flex justify-between items-center p-4">
            <div className="flex gap-2 items-center">
              <h2 className="text-[36px] font-[500] text-[#363C45]">
                Loan Directory
              </h2>{" "}
              {/* Display number of results */}
              <span className="text-[#535C69] text-[12px] font-[500] mt-4">
                {filteredData.length} Loans
              </span>
              <button
                onClick={() => setShowChat(true)}
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition"
              >
                Open Chat
              </button>
              {showChat && <Chat onClose={() => setShowChat(false)} />}
            </div>
            <div className="flex space-x-4">
              <div className="relative">
                <input
                  type="text"
                  id="searchBar"
                  className="border border-gray-300 rounded-md p-2 text-sm ps-6"
                  placeholder="Search loans"
                  onChange={searchLoans}
                />
                <img
                  src="/images/MagnifyingGlass.svg"
                  width={16}
                  height={16}
                  className="absolute top-[12px] left-2"
                  alt=""
                />
              </div>
              <button
                onClick={addItem}
                className="bg-[#2183F6] text-white px-4 py-2 rounded-md hover:bg-blue-600 transition"
              >
                Add New Loan +
              </button>
            </div>
          </div>
          <div className="filter-sliders-container flex items-center gap-2 p-3">
            <button className="filter-slider text-[#363C45] flex gap-2 text-[11px] items-center rounded-full bg-[#C2C7CF] px-[8px] py-[6px]">
              <span className="w-[24px] h-[24px] bg-white rounded-full">
                <img
                  src="/images/tick-circle.svg"
                  width={24}
                  height={24}
                  alt=""
                />
              </span>{" "}
              Show All
            </button>
            <button className="filter-slider text-white flex gap-2 text-[11px] items-center rounded-full bg-[#2E90FA] px-[8px] py-[6px]">
              <span className="w-[24px] h-[24px] bg-white rounded-full"></span>{" "}
              Requires Action
            </button>
            <button className="filter-slider text-white flex gap-2 text-[11px] items-center rounded-full bg-[#039855] px-[8px] py-[6px]">
              <span className="w-[24px] h-[24px] bg-white rounded-full"></span>{" "}
              Completed
            </button>
            <button
              className={`filter-slider pending-slider text-white flex gap-2 text-[11px] items-center rounded-full bg-[#EAB816] px-[8px] py-[6px]`}
              onClick={() => handleFilterClick("pending")}
            >
              {activeFilter === "pending" ? (
                <>
                  Pending
                  <span className="w-[24px] h-[24px] bg-white rounded-full bg-black"></span>
                </>
              ) : (
                <>
                  <span className="w-[24px] h-[24px] bg-white rounded-full bg-white"></span>
                  Pending
                </>
              )}
            </button>
            <button className="filter-slider text-white flex gap-2 text-[11px] items-center rounded-full bg-[#E75B51] px-[8px] py-[6px]">
              <span className="w-[24px] h-[24px] bg-white rounded-full"></span>{" "}
              Rejected
            </button>
            <div className="px-[12px] border-l-2">
              <button className="filter-slider text-[#535C69] flex gap-2 text-[11px] items-center rounded-full bg-[#C2C7CF] px-[8px] py-[6px]">
                <span className="w-[24px] h-[24px] bg-white rounded-full"></span>{" "}
                Archived
              </button>
            </div>
          </div>

          <div>
            <p className="text-[12px] text-[#535c69] px-4 mb-3">
              Displaying {filteredData.length} results
            </p>
            <DataTable
              columns={columns}
              data={filteredData}
              pagination
              defaultSortField="loan_name"
              className="table-auto w-[100vw] bg-transparent"
              onRowClicked={handleRowClick}
              highlightOnHover
              pointerOnHover
              customStyles={customStyles}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const ProgressBar = ({ currentStep, steps }) => {
  // Ensure currentStep is a valid number
  const validCurrentStep = Number(currentStep) || 0;
  const totalSteps = (steps?.length || 1) - 1;

  // Ensure we don't create arrays with negative lengths
  const filledBlocks = Math.max(0, Math.min(validCurrentStep, totalSteps));
  const unfilledBlocks = Math.max(0, totalSteps - filledBlocks);
  const pillWidth = `w-[8%]`;

  // Check if current step is pending
  const isCurrentStepPending = steps[validCurrentStep]?.pending;

  return (
    <div className="progress-bar flex flex-wrap items-center my-2 space-x-2 mt-5 wrap w-full">
      {/* Filled Blocks (Green) */}
      {Array.from({ length: Math.max(0, filledBlocks - 1) }).map((_, index) => (
        <div
          key={`filled-${index}`}
          className={`progress-pill completed-pill ${pillWidth} h-[0.5rem] bg-[#0BBC78] rounded-full`}
        />
      ))}
      {/* Last Filled Block (Blue) */}
      {filledBlocks > 0 && (
        <div
          key={`filled-last`}
          className={`progress-pill last-filled-pill ${
            isCurrentStepPending && "pending-pill"
          }
                       ${pillWidth} h-[0.5rem] rounded-full
                        ${
                          isCurrentStepPending
                            ? "bg-yellow-400"
                            : "bg-[#0B51BC]"
                        }`}
        />
      )}
      {/* Unfilled Blocks (Gray) */}
      {Array.from({ length: unfilledBlocks }).map((_, index) => (
        <div
          key={`unfilled-${index}`}
          className={`progress-pill unfilled-pill ${pillWidth} h-[0.5rem] bg-gray-300 rounded-full`}
        />
      ))}
    </div>
  );
};

export default FormsDynamicTable;
