import React, { useState, useEffect, useRef } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { highlightPlugin } from "@react-pdf-viewer/highlight";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import "@react-pdf-viewer/highlight/lib/styles/index.css";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import * as pdfjsLib from "pdfjs-dist";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { useTheme } from "../context/ThemeContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useSocket } from "../context/SocketContext";
import axios from "axios";
import CircularButton from "./CircularButton";
import Maximize from "./img/Maximize";
import CloseCircle from "./img/closeCircle";
import RectButton from "./RectButton";

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const PDFViewertester = () => {
  const location = useLocation();
  const {
    pdfBase64: initialPdfUrl,
    jsonData: initialJsonData,
    iframe: initialIframeData,
    formId: initialFormId,
    loanName: loanName,
    progressStep: progressStep,
    pdfBase64NorCalTemplate,
    docAiResultsNorCalTemplate,
  } = location.state || {};
  const navigate = useNavigate();

  // // Add check for required state data
  // useEffect(() => {
  //   const requiredState = ["pdfBase64", "jsonData", "formId"];
  //   const missingState = requiredState.filter(
  //     (key) => !location.state || !location.state[key]
  //   );

  //   if (missingState.length > 0) {
  //     console.warn("Missing required state:", missingState);
  //     // Navigate back to dynamic table if state is missing
  //     navigate("/dynamic-table", { replace: true });
  //     return;
  //   }
  // }, [location.state, navigate]);

  const [pdfUrl, setPdfUrl] = useState();
  const [pdfBase64, setPdfBase64] = useState(initialPdfUrl || null);
  const [jsonData, setJsonData] = useState([]);
  const [formId, setFormId] = useState(initialFormId || null);
  const [googleDocsUrl, setGoogleDocsUrl] = useState(
    initialIframeData ||
      "https://docs.google.com/document/d/1YXu_Uu6Z5hdjqjRUX71PU1D_KPiKWe1Q/edit?usp=sharing&ouid=103844593516674711347&rtpof=true&sd=true"
  );
  const [highlightedAreas, setHighlightedAreas] = useState([]);
  const [temporaryHighlights, setTemporaryHighlights] = useState([]);
  const [isHighlightVisible, setIsHighlightVisible] = useState(true);
  const [showAllHighlights, setShowAllHighlights] = useState(true);
  const { darkMode, toggleTheme } = useTheme();
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [isApiLoading, setIsApiLoading] = useState(false);
  const { socket, isConnected } = useSocket();
  // const fullScreenSectionClassName = "fullscreen";
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isSplitScreen, setIsSplitScreen] = useState(false);
  const breakpointToStartVerticalView = "lg";
  const [isProcessing, setIsProcessing] = useState(false);

  const themeStyles = {
    backgroundColor: darkMode ? "#121212" : "#FFFFFF",
    color: darkMode ? "white" : "black",
    borderColor: darkMode ? "#1e1e1e" : "#CCCCCC",
    buttonBackgroundColor: "#6A55FE",
  };

  const zoomPluginInstance = zoomPlugin();
  const highlightPluginInstance = highlightPlugin({
    renderHighlights: (props) => {
      return (
        <div>
          {showAllHighlights &&
            highlightedAreas.map(
              (area, idx) =>
                area.pageIndex === props.pageIndex && (
                  <div
                    key={idx}
                    style={{
                      background: "#2183F6",
                      opacity: 0.5,
                      position: "absolute",
                      top: `${area.top * 100}%`,
                      left: `${area.left * 100}%`,
                      height: `${area.height * 100}%`,
                      width: `${area.width * 100}%`,
                      pointerEvents: "none",
                      transform: `rotate(${props.rotation}deg)`,
                      transition: "opacity 2s",
                    }}
                  />
                )
            )}
          {temporaryHighlights.map(
            (area, idx) =>
              area.pageIndex === props.pageIndex && (
                <div
                  key={idx}
                  style={{
                    background: "#2183F6",
                    opacity: isHighlightVisible ? 0.5 : 0,
                    position: "absolute",
                    top: `${area.top * 100}%`,
                    left: `${area.left * 100}%`,
                    height: `${area.height * 100}%`,
                    width: `${area.width * 100}%`,
                    pointerEvents: "none",
                    transform: `rotate(${props.rotation}deg)`,
                    transition: "opacity 2s",
                  }}
                />
              )
          )}
        </div>
      );
    },
  });

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { jumpToPage } = pageNavigationPluginInstance;

  useEffect(() => {
    if (pdfBase64) {
      // Add a small delay to ensure proper initialization
      const timer = setTimeout(() => {
        try {
          const byteCharacters = atob(pdfBase64);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "application/pdf" });
          const objectUrl = URL.createObjectURL(blob);
          setPdfUrl(objectUrl);
        } catch (error) {
          console.error("Error converting base64 to Blob:", error);
        }
      }, 100); // 100ms delay

      return () => {
        clearTimeout(timer);
      };
    }
  }, [pdfBase64]);

  useEffect(() => {
    if (initialPdfUrl && initialJsonData) {
      setJsonData(initialJsonData.documents[0].fields);
      extractAndHighlightFromJson(initialJsonData.documents[0].fields);
    }
  }, [initialPdfUrl, initialJsonData]);

  useEffect(() => {
    const handleFormCopilotResponse = (response) => {
      console.log("[custom pdf viewer] form copilot response received");
      if (
        response &&
        response.documents &&
        response.documents[0] &&
        response.documents[0].fields
      ) {
        setJsonData(response.documents[0].fields);
        extractAndHighlightFromJson(response.documents[0].fields);
      } else {
        console.error("Invalid response structure:", response);
        setJsonData({ error: "Invalid response structure" });
      }
      // Clear loading states
      setIsProcessing(false);
      setLoading(false);
    };

    const handleError = (error) => {
      console.error("Socket error:", error.msg);
      setJsonData({ error: error.msg });
      // Clear loading states
      setIsProcessing(false);
      setLoading(false);
    };

    // Add event listeners
    socket.on("form_copilot_response", handleFormCopilotResponse);
    socket.on("error", handleError);

    // Cleanup function
    return () => {
      socket.off("form_copilot_response", handleFormCopilotResponse);
      socket.off("error", handleError);
    };
  }, [socket]);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Set loading states immediately when file is selected
    setIsProcessing(true);
    setLoading(true);

    const fileUrl = URL.createObjectURL(file);
    setPdfUrl(fileUrl);
    setFileName(file.name);

    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result;
      const base64Content = base64String.split(",")[1];
      const isDivisibleBy4 = base64Content.length % 4 === 0;
      let paddedBase64Content = base64Content;
      if (!isDivisibleBy4) {
        const missingPadding = 4 - (base64Content.length % 4);
        paddedBase64Content += "=".repeat(missingPadding);
      }
      const base64StringWithPadding = `${
        base64String.split(",")[0]
      },${paddedBase64Content}`;
      console.log(
        "[CustomPDFViewer] emitting form_copilot with formId: ",
        formId
      );

      if (!socket) {
        console.error("Socket not connected");
        setIsProcessing(false);
        setLoading(false);
        return;
      }

      socket.emit("form_copilot", {
        data: {
          body: base64StringWithPadding,
          formId: formId,
        },
      });
    };

    reader.onerror = (error) => {
      console.error("Error reading file:", error);
      setJsonData({ error: error.toString() });
      setIsProcessing(false);
      setLoading(false);
    };

    reader.readAsDataURL(file);
  };

  const extractAndHighlightFromJson = (jsonData) => {
    const highlightedAreas = [];

    Object.entries(jsonData).forEach(([key, field]) => {
      if (field.boundingRegions) {
        field.boundingRegions.forEach((region) => {
          const polygon = region.polygon;
          const [x1, y1, x2, y2, x3, y3, x4, y4] = polygon;
          const left = Math.min(x1, x2, x3, x4);
          const top = Math.min(y1, y2, y3, y4);
          const width = Math.max(x1, x2, x3, x4) - left;
          const height = Math.max(y1, y2, y3, y4) - top;

          highlightedAreas.push({
            top: top / 11,
            left: left / 8.5,
            height: height / 11,
            width: width / 8.5,
            pageIndex: region.pageNumber - 1,
            fieldKey: key,
          });
        });
      }
    });

    setHighlightedAreas(highlightedAreas);
  };

  const handlePercentages = (x) => {
    return Math.round(x);
  };

  const handlePanelClick = (boundingRegions) => {
    setShowAllHighlights(false);
    setHighlightedAreas([]);
    if (boundingRegions && boundingRegions.length > 0) {
      const region = boundingRegions[0];
      const pageIndex = region.pageNumber - 1;
      jumpToPage(pageIndex);

      const [x1, y1, x2, y2, x3, y3, x4, y4] = region.polygon;
      const left = Math.min(x1, x2, x3, x4);
      const top = Math.min(y1, y2, y3, y4);
      const width = Math.max(x1, x2, x3, x4) - left;
      const height = Math.max(y1, y2, y3, y4) - top;

      const highlightedArea = {
        top: top / 11,
        left: left / 8.5,
        height: height / 11,
        width: width / 8.5,
        pageIndex: pageIndex,
      };

      setTemporaryHighlights([highlightedArea]);
      setIsHighlightVisible(true);
      setTimeout(() => {
        setIsHighlightVisible(false);
        setTimeout(() => {
          setTemporaryHighlights([]);
        }, 2000);
      }, 1000);
    }
  };

  const toggleShowAllHighlights = () => {
    setShowAllHighlights(!showAllHighlights);
  };

  useEffect(() => {
    if (showAllHighlights) {
      setTemporaryHighlights([]);
      setIsHighlightVisible(false);
    }
  }, [showAllHighlights]);

  const handleApiCall = () => {
    console.log("updating google docs");
    setIsApiLoading(true);
    const dataToSend = Object.entries(jsonData).map(([key, field]) => ({
      title: key,
      content: field.valueString,
    }));

    axios({
      method: "post",
      url: "https://api.formcopilot.app/generate_google_docs",
      data: {
        formId: formId,
        dataToSend,
      },
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true, // Ensure cookies are sent with the request
    })
      .then((response) => {
        if (response.data && response.data.shareableLink) {
          setGoogleDocsUrl(response.data.shareableLink);
        }
        setIsApiLoading(false);
      })
      .catch((error) => {
        console.error("Error making API call:", error);
        setIsApiLoading(false);
      });
  };

  // New function to handle PDF download
  const handleDownloadPdf = () => {
    if (pdfUrl) {
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = fileName || "Credit Memo.pdf";
      link.click();
    }
  };

  function findParentWithClass(child, className) {
    let parent = child.parentElement;
    while (parent) {
      if (parent.classList.contains(className)) {
        return parent;
      }
      parent = parent.parentElement;
    }
    return null; // Return null if no parent with the class is found
  }

  function findChildWithClass(parent, targetClassName) {
    let parentChildren = parent.children;
    for (const child of parentChildren) {
      if (child.classList.contains(targetClassName)) {
        return child;
      }
    }
    return null;
  }

  function findPreviousElementInDOMWithClass(start, targetClassName) {
    let previousElement = start.previousElementSibling;
    while (previousElement) {
      if (previousElement.classList.contains(targetClassName)) {
        return previousElement;
      }
      previousElement = previousElement.previousElementSibling;
    }
    return null;
  }

  function hideAllCollapsiblesAndStartFullScreen(e) {
    const fullScreenBtn = e.target;
    var elementToFullScreen = findParentWithClass(
      fullScreenBtn,
      "collapsible-section"
    );
    elementToFullScreen.classList.add(
      allDerivedClassNames.fullScreenSectionClassName
    );
    elementToFullScreen.classList.add("!w-[100vw]");

    // find all other collapsible sections and hide them
    const allCollapsibleSections = document.querySelectorAll(
      ".collapsible-section"
    );

    allCollapsibleSections.forEach((section) => {
      if (
        !section.classList.contains(
          allDerivedClassNames.fullScreenSectionClassName
        )
      ) {
        section.classList.add("!hidden");
      }
    });
    // hide fullscreen button and reveal hide fullscreen button next to it
    setIsFullScreen(true);
  }

  function endFullScreenAndRevealCollapsibles(e) {
    const endFullScreenBtn = e.target;
    var elementToResize = findParentWithClass(
      endFullScreenBtn,
      allDerivedClassNames.fullScreenSectionClassName
    );
    elementToResize.classList.remove(
      allDerivedClassNames.fullScreenSectionClassName
    );
    elementToResize.classList.remove("!w-[100vw]");
    // reveal collapsibles
    const allCollapsibleSections = document.querySelectorAll(
      ".collapsible-section"
    );

    for (const section of allCollapsibleSections) {
      // do not reveal the memo details section if splitscreen mode is currently on
      if (
        section.classList.contains(
          `${allDerivedClassNames.memoDetailsSectionClassname}`
        ) &&
        isSplitScreen
      ) {
        continue;
      }
      if (section.classList.contains("!hidden")) {
        section.classList.remove("!hidden");
      }
    }

    // toggle view for fullscreen toggling btns
    setIsFullScreen(false);
  }

  // toggle splitscreen (memo details disappear)
  function toggleSplitScreen() {
    const memoDetailsSection = document.querySelector(
      `.${allDerivedClassNames.memoDetailsSectionClassname}`
    );
    switch (isSplitScreen) {
      case true:
        // reveal memo details section
        memoDetailsSection.classList.remove("!hidden");
        setIsSplitScreen(false);
        break;
      case false:
        // hide memo details section
        memoDetailsSection.classList.add("!hidden");
        setIsSplitScreen(true);
        break;
    }
  }

  const allDerivedClassNames = {
    mainPageToolbarClassname: "main-page-toolbar",
    fullScreenSectionClassName: "fullscreen-div",
    fullscreenBtnClassname: "fullscreen-btn",
    memoDetailsSectionClassname: "memo-details-section",
  };

  const StartFullscreenBtn = (
    <CircularButton
      className={`${
        allDerivedClassNames.fullscreenBtnClassname
      } start-fullscreen-btn absolute right-[0] ${
        !isFullScreen ? "" : "!hidden"
      }`}
      onClick={hideAllCollapsiblesAndStartFullScreen}
      content={<Maximize />}
    />
  );

  const EndFullscreenBtn = (
    <CircularButton
      className={`${
        allDerivedClassNames.fullscreenBtnClassname
      } end-fullscreen-btn absolute right-[0] ${isFullScreen ? "" : "!hidden"}`}
      onClick={endFullScreenAndRevealCollapsibles}
      content={<CloseCircle />}
    />
  );

  const fullscreenToggleBtnsContainer = (
    <div className="fullscreen-toggle-btns-container relative z-[400]">
      {StartFullscreenBtn}
      {EndFullscreenBtn}
    </div>
  );

  const mainPageToolbar = (
    <div
      className={` ${allDerivedClassNames.mainPageToolbarClassname} collapsible-section flex justify-between items-center`}
    >
      <div>
        <button
          className="w-[44px] h-[44px] bg-white flex items-center justify-center rounded-[4px]"
          onClick={() => {
            const returnPath =
              location.state?.returnPath || "/individual-loan-tracker";
            navigate(returnPath);
          }}
        >
          <img
            src="/images/ArrowLeft.svg"
            width={24}
            height={24}
            alt="Go Back"
          />
        </button>
      </div>
      <div className="flex items-center gap-2 p-2 ps-0">
        <RectButton
          className="split-screen-button"
          onClick={toggleSplitScreen}
          content={
            <p>{isSplitScreen ? "Show Memo Details" : "Hide Memo Details"}</p>
          }
        />

        <label
          htmlFor="file-upload"
          className="hover:scale-105 duration-300 ease-in-out cursor-pointer bg-white text-[#2183F6] font-[500] px-[20px] py-[10px] rounded-[4px] flex items-center gap-1"
        >
          {loading ? (
            <>
              Processing... <i className="fas fa-spinner fa-spin fa-1x"></i>
            </>
          ) : (
            <>
              Upload Document{" "}
              <img
                src="/images/FileArrowUp.svg"
                width={16}
                height={16}
                alt="UploadFile"
              />
            </>
          )}
        </label>
        <input
          id="file-upload"
          type="file"
          style={{ display: "none" }}
          onChange={handleFileChange}
          accept="application/pdf"
          required
        />
        <RectButton
          className="show-all-highlights-button"
          onClick={toggleShowAllHighlights}
          content={
            <>
              {showAllHighlights ? "Hide Highlights" : "Show Highlights"}
              <img src="/images/Eye.svg" width={16} height={16} alt="EyeIcon" />
            </>
          }
        />
        <RectButton
          className="autofill-word-doc-button"
          onClick={handleApiCall}
          content={
            isApiLoading ? (
              <i className="fas fa-spinner fa-spin"></i>
            ) : (
              <>
                <span>Autofill Word Doc</span>
                <img
                  src="/images/PencilSimple.svg"
                  width={16}
                  height={16}
                  alt="PencilIcon"
                />
              </>
            )
          }
        />
        <RectButton
          className="download-pdf-button"
          onClick={handleDownloadPdf}
          content={
            <>
              Download PDF
              <img
                src="/images/FileArrowDown.svg"
                width={16}
                height={16}
                alt="Download"
              />
            </>
          }
        />
        <button
          className="w-[44px] h-[44px] bg-white flex items-center justify-center rounded-[4px]"
          onClick={() => {
            navigate("/dynamic-table");
          }}
        >
          <img
            src="/images/HouseSimple.svg"
            width={24}
            height={24}
            alt="Go Back"
          />
        </button>
      </div>
    </div>
  );

  const ProcessingOverlay = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg text-center">
        <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-blue-500 mx-auto mb-4"></div>
        <h2 className="text-xl font-semibold mb-2">Processing Document</h2>
        <p className="text-gray-600">
          Please wait while we analyze your document...
        </p>
      </div>
    </div>
  );

  return (
    <div className="bg-[#f1f1f1]">
      {isProcessing && <ProcessingOverlay />}
      {isSplitScreen && mainPageToolbar}
      <div
        className="flex max-lg:flex-col"
        style={{ height: "100vh", overflow: "hidden" }}
      >
        <div
          className="collapsible-section"
          style={{ width: "100%", height: "100%", border: "none" }}
        >
          {fullscreenToggleBtnsContainer}

          <iframe
            src={googleDocsUrl}
            className="google-docs-iframe w-full h-full"
            title="Google Document"
          ></iframe>
        </div>
        <div
          className="main-page-toolbar-pdf-viewer-and-memo-details-container"
          style={{ padding: "5px 20px", position: "relative" }}
        >
          {!isSplitScreen && mainPageToolbar}
          {pdfUrl && (
            <div
              className="pdf-viewer-and-memo-details-container flex"
              style={{ position: "relative", width: "100%", height: "95vh" }}
            >
              <div className="lender-memo-viewer-container collapsible-section min-w-[700px]">
                {fullscreenToggleBtnsContainer}
                <Worker workerUrl={pdfjsWorker}>
                  <div style={{ height: "100%", width: "100%" }}>
                    <Viewer
                      fileUrl={pdfUrl}
                      plugins={[
                        zoomPluginInstance,
                        highlightPluginInstance,
                        pageNavigationPluginInstance,
                      ]}
                      onDocumentLoad={() => console.log("Document loaded")}
                      defaultScale={0.95}
                    />
                  </div>
                </Worker>
              </div>
              <div
                className={`${allDerivedClassNames.memoDetailsSectionClassname} collapsible-section py-[0rem] pr-[10px] pl-[20px] h-full`}
                style={{ width: "370px" }}
              >
                <div
                  style={{ width: "100%", overflowY: "auto", height: "90vh" }}
                  className="Details memo-details-container h-[80vh] pb-[2rem]"
                >
                  {jsonData ? (
                    <>
                      {Object.entries(jsonData).map(([key, value], index) => (
                        <div
                          className="memo-details-card relative text-black bg-white px-[10px] py-[1rem] leading-[1rem] mb-[2px] cursor-pointer rounded-[0.25rem]"
                          key={index}
                          onClick={() =>
                            handlePanelClick(value.boundingRegions)
                          }
                        >
                          <div
                            className="memo-details-card-header-and-percentage"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              marginBottom: "0.5rem",
                            }}
                          >
                            <h4
                              className="memo-details-card-header"
                              style={{
                                textTransform: "capitalize",
                                margin: "0",
                                color: "#535C69",
                              }}
                            >
                              {key}
                            </h4>
                            <p
                              className="memo-details-card-percentage font-[500] text-[15px]"
                              style={{
                                color: `${
                                  value.confidence * 100 <= 33
                                    ? "red"
                                    : "#363C45"
                                }`,
                              }}
                            >
                              {handlePercentages(value.confidence * 100)}%
                            </p>
                          </div>
                          <p
                            className="memo-details-card-value"
                            style={{
                              fontSize: "14px",
                              fontWeight: "bold",
                              color: "#363C45",
                            }}
                          >
                            {value.valueString}
                          </p>
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {loading ? (
                          <i className="fas fa-spinner fa-spin fa-3x"></i>
                        ) : (
                          <h2>Please Upload Document</h2>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PDFViewertester;
