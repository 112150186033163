import React, {
  useReducer,
  useEffect,
  useCallback,
  useMemo,
  useState,
  useRef,
} from "react";
import styled from "styled-components";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { Worker, Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useSocket } from "../context/SocketContext";
import StepNoteSection from "./stepNoteSection";
import { ProcessSteps } from "./ProcessSteps";
import { DocumentSection } from "./ui/document";
import { all } from "axios";
import { axiosInstance } from "../utils/queryClient";
import { toast } from "react-hot-toast";

// Constants
const API_ENDPOINTS = {
  START_CAR_APPROVAL: "https://api.formcopilot.app/action_extension_crf_portal",
  CREATE_NOR_CAL_FORM:
    "https://api.formcopilot.app/create_nor_cal_form_web_app",
  CAR_APPROVED: "https://api.formcopilot.app/action_extension_2_car_approved",
  UPDATE_6_QUESTIONS: "https://api.formcopilot.app/update_6_questions_web_app",
};

const STEP_API_MAPPING = {
  1: API_ENDPOINTS.START_CAR_APPROVAL,
  5: API_ENDPOINTS.CREATE_NOR_CAL_FORM,
  7: API_ENDPOINTS.CAR_APPROVED,
  12: API_ENDPOINTS.UPDATE_6_QUESTIONS,
};

const STEP_CONSTANTS = {
  MIN_STEP: 1,
  MAX_STEP: 11,
  DEFAULT_STEP: 1,
};

const validateStep = (step) => {
  return Math.min(
    Math.max(step, STEP_CONSTANTS.MIN_STEP),
    STEP_CONSTANTS.MAX_STEP
  );
};

const PDFContainer = styled.div`
  width: 48%;
  height: 620px;
  border: 2px solid #ccc;
  padding: 10px;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  aspect-ratio: 8.5 / 11;
`;

// Utility Functions
const convertBase64ToPdfUrl = (base64String) => {
  if (!base64String) return null;
  try {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });
    return URL.createObjectURL(blob);
  } catch (error) {
    console.error(
      "[IndividualLoanTracker] Error converting base64 to Blob:",
      error
    );
    return null;
  }
};

// Components
const PDFViewer = ({ pdfUrl, onClick, placeholder }) => (
  <PDFContainer onClick={onClick}>
    {pdfUrl ? (
      <div style={{ height: "100%", width: "100%" }}>
        <Viewer
          fileUrl={pdfUrl}
          onDocumentLoad={() => console.log("Document loaded")}
          defaultScale={SpecialZoomLevel.PageWidth}
        />
      </div>
    ) : (
      <div className="flex flex-col items-center">
        <img
          src="/images/FileArrowUp.svg"
          width={48}
          height={48}
          alt="FileArrowUp"
        />
        <p className="text-primary-default-blue font-[500]">
          {placeholder || "Upload document"}
        </p>
      </div>
    )}
  </PDFContainer>
);

const ProgressBar = ({ currentStep, steps }) => {
  const totalSteps = steps.length - 2;
  const filledBlocks = currentStep;
  const unfilledBlocks = totalSteps - filledBlocks;
  const isCurrentStepPending = steps[currentStep]?.pending;

  return (
    <div className="progress-bar flex items-center my-2 space-x-3 px-[2rem] mt-5 wrap">
      {Array(filledBlocks - 1)
        .fill(0)
        .map((_, index) => (
          <div
            key={`filled-${index}`}
            className="progress-pill completed-pill w-[177px] h-[24px] bg-[#0BBC78] rounded-full"
          />
        ))}
      {filledBlocks > 0 && (
        <div
          key="filled-last"
          className={`progress-pill last-filled-pill w-[177px] h-[24px] rounded-full ${
            isCurrentStepPending ? "bg-yellow-400" : "bg-[#0B51BC]"
          }`}
        />
      )}
      {Array(unfilledBlocks)
        .fill(0)
        .map((_, index) => (
          <div
            key={`unfilled-${index}`}
            className="progress-pill unfilled-pill w-[177px] h-[24px] bg-gray-300 rounded-full"
          />
        ))}
    </div>
  );
};

const StepSlider = ({
  steps,
  currentStep,
  onStepChange,
  onAutomationClick,
}) => {
  console.log(steps, currentStep);
  const [activeSlideIndex, setActiveSlideIndex] = useState(currentStep - 1);

  const handleSlideChange = (swiper) => {
    const newIndex = swiper.activeIndex;
    const newStep = newIndex + 1;

    if (newStep !== currentStep) {
      setActiveSlideIndex(newIndex);
      onStepChange(newStep);
    }
  };

  return (
    <Swiper
      spaceBetween={10}
      slidesPerView={3}
      modules={[Navigation, Autoplay]}
      navigation={{
        prevEl: ".swiper-button-prev",
        nextEl: ".swiper-button-next",
      }}
      className="mySwiper"
      style={{ height: "6rem" }}
      initialSlide={currentStep - 1}
      onSlideChange={handleSlideChange}
      allowSlideNext={currentStep < STEP_CONSTANTS.MAX_STEP}
      allowSlidePrev={currentStep > STEP_CONSTANTS.MIN_STEP}
      loop={false}
      watchOverflow={true}
      slidesPerGroup={1}
      resistance={false}
      preventInteractionOnTransition={true}
    >
      {steps.map((step, index) => {
        const label =
          index + 1 < currentStep
            ? "Previous Step"
            : index === currentStep
            ? "Current Step"
            : "Next Step";
        const isActive = activeSlideIndex + 1 === index;

        return (
          <SwiperSlide key={step.id}>
            <div
              className={
                isActive ? "px-[2rem] py-[1rem]" : "px-[4rem] py-[1rem]"
              }
              style={{
                color: isActive ? "black" : "#A9A9A9",
                height: "6rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "left",
                borderRadius: "8px",
                textAlign: "left",
              }}
            >
              <span
                className="text-start text-[#535C69]"
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  marginBottom: isActive ? "-5px" : "0px",
                }}
              >
                {label}
              </span>
              <div
                className="flex items-center justify-between"
                style={{ marginBottom: isActive ? "-5px" : "0px" }}
              >
                <h3
                  style={{
                    fontSize: isActive ? "18px" : "15px",
                    textAlign: "start",
                  }}
                  className="font-[600] leading-[20px]"
                >
                  {step.title}
                </h3>
                {isActive && (
                  <button
                    onClick={() => onAutomationClick(step.id)}
                    className="ml-[1rem] px-[12px] py-[8px] bg-primary-default-blue flex items-center gap-1 rounded-sm text-white font-[500] text-[12px]"
                  >
                    Automation{" "}
                    <img
                      src="/images/MagicStick.svg"
                      width={16}
                      height={16}
                      alt="MagicStick"
                    />
                  </button>
                )}
              </div>
              {isActive && <StepNoteSection />}
            </div>
          </SwiperSlide>
        );
      })}
      <div
        className="swiper-button-next bg-primary-default-blue disabled:bg-surface-secondary-white w-[2rem] h-[2.5rem] right-0 rounded-l-[0.25rem]"
        style={{ top: "70%" }}
      />
      <div
        className="swiper-button-prev bg-primary-default-blue disabled:bg-surface-secondary-white w-[2rem] h-[2.5rem] left-0 rounded-r-[0.25rem]"
        style={{ top: "70%" }}
      />
    </Swiper>
  );
};

const useFileUpload = (
  formId,
  progressStep,
  updateData,
  socket,
  additionalFiles,
  setAdditionalFiles
) => {
  const uploadFile = async (file) => {
    if (!file?.name) {
      console.error("Invalid file object:", file);
      toast.error("Invalid file");
      return { success: false, message: "Invalid file" };
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("loan_id", formId);
    formData.append("step", progressStep);
    formData.append("part", "additional_files");

    try {
      const { data } = await axiosInstance.post("/files/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (!data.success) throw new Error(data.message || "Upload failed");

      return {
        success: true,
        message: data.message,
        data: {
          key: data.data.key,
          url: data.data.url,
          name: file.name,
        },
      };
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(`Upload failed: ${errorMessage}`);
      return { success: false, message: errorMessage };
    }
  };

  const deleteFile = async (fileKey) => {
    try {
      const { data } = await axiosInstance.delete("/files/delete", {
        params: {
          loan_id: formId,
          step: progressStep,
          part: "part_1_additional",
          key: fileKey,
        },
      });

      if (!data.success) throw new Error(data.message || "Delete failed");

      setAdditionalFiles((prev) => prev.filter((file) => file.key !== fileKey));
      updateData((prevData) => ({
        ...prevData,
        additionalData:
          prevData?.additionalData?.filter((file) => file.key !== fileKey) ||
          [],
      }));

      return data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(`Delete failed: ${errorMessage}`);
      throw new Error(errorMessage);
    }
  };

  return {
    uploadFile,
    deleteFile,
  };
};

// Main Component
const IndividualLoanTracker = () => {
  const [searchParams] = useSearchParams();
  const formId = searchParams.get("id");
  const navigate = useNavigate();
  const { socket, isConnected } = useSocket();
  const isMounted = useRef(true);
  const dataFetchedRef = useRef(false);
  const [additionalFiles, setAdditionalFiles] = useState([]);

  const initialState = {
    initialData: {
      pdfBase64: null,
      jsonData: null,
      iframe: null,
      formId: formId,
      progressStep: 2,
      pdfBase64NorCalTemplate: null,
      docAiResultsNorCalTemplate: null,
      loan_name: "",
      additionalData: [],
    },
    steps: ProcessSteps,
    showPopup: false,
  };

  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "SET_INITIAL_DATA":
        return {
          ...state,
          initialData: { ...state.initialData, ...action.payload },
        };
      case "SET_STEPS":
        return { ...state, steps: action.payload };
      case "SET_SHOW_POPUP":
        return { ...state, showPopup: action.payload };
      default:
        return state;
    }
  }, initialState);

  const [currentStep, setCurrentStep] = useState(
    state.initialData.progressStep || 1
  );
  const [isDataReady, setIsDataReady] = useState(false);

  const pdfUrl1 = useMemo(
    () => convertBase64ToPdfUrl(state.initialData.pdfBase64),
    [state.initialData.pdfBase64]
  );
  const pdfUrl2 = useMemo(
    () => convertBase64ToPdfUrl(state.initialData.pdfBase64NorCalTemplate),
    [state.initialData.pdfBase64NorCalTemplate]
  );

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
      if (pdfUrl1) URL.revokeObjectURL(pdfUrl1);
      if (pdfUrl2) URL.revokeObjectURL(pdfUrl2);
    };
  }, [pdfUrl1, pdfUrl2]);

  useEffect(() => {
    if (!formId || !socket || !isConnected || dataFetchedRef.current) return;

    const fieldsToFetch = [
      "pdfBase64",
      "docAiResults",
      "googleDocsIframe",
      "_id",
      "loan_name",
      "progress_step",
      "pdfBase64NorCalTemplate",
      "docAiResultsNorCalTemplate",
      "additional_files",
    ];

    console.log("[IndividualLoanTracker] Fetching data for formId:", formId);

    const handleItemData = (data) => {
      if (!isMounted.current || !data?.[0]) return;

      const fetchedData = data[0];
      console.log("[IndividualLoanTracker] Received data:", fetchedData);

      if (fetchedData.additional_files) {
        const additionalFiles = fetchedData.additional_files.map((file) => ({
          key: file.key,
          name: file.name,
          url: file.url,
        }));
        setAdditionalFiles(additionalFiles);
      }

      console.log("fetchedData.progress_step:", fetchedData.progress_step);
      const step = fetchedData.progress_step || 2;
      console.log("currentStep:", step);

      dispatch({
        type: "SET_INITIAL_DATA",
        payload: {
          pdfBase64: fetchedData.pdfBase64,
          jsonData: fetchedData.docAiResults,
          iframe: fetchedData.googleDocsIframe,
          formId: fetchedData._id,
          progressStep: step,
          pdfBase64NorCalTemplate: fetchedData.pdfBase64NorCalTemplate,
          docAiResultsNorCalTemplate: fetchedData.docAiResultsNorCalTemplate,
          loan_name: fetchedData.loan_name,
          additionalData: fetchedData.additional_files || [],
        },
      });
      // setCurrentStep(step);
      setIsDataReady(true);
      dataFetchedRef.current = true;
    };

    socket.emit("get_items", { id: formId, fields: fieldsToFetch });
    socket.on("item", handleItemData);

    return () => {
      socket.off("item", handleItemData);
    };
  }, [formId, socket, isConnected]);

  useEffect(() => {
    if (socket && isConnected) {
      const handleEmailSent = (response) => {
        console.log("[IndividualLoanTracker] Email sent:", response.msg);
        dispatch({ type: "SET_SHOW_POPUP", payload: true });
        setTimeout(
          () => dispatch({ type: "SET_SHOW_POPUP", payload: false }),
          3000
        );
      };

      socket.on("email_sent", handleEmailSent);
      return () => socket.off("email_sent", handleEmailSent);
    }
  }, [socket, isConnected]);

  const handleStepChange = useCallback(
    (stepId) => {
      console.log("Changing to step:", stepId);
      const validatedStep = validateStep(stepId);
      dispatch({
        type: "SET_STEPS",
        payload: state.steps.map((step) => ({
          ...step,
          completed: step.id < validatedStep,
        })),
      });

      dispatch({
        type: "SET_INITIAL_DATA",
        payload: { progressStep: stepId },
      });

      setCurrentStep(stepId);

      if (socket) {
        socket.emit(
          "update_items",
          { items: [{ _id: state.initialData.formId, progress_step: stepId }] },
          (response) =>
            console.log(
              "[IndividualLoanTracker] Update Items Response:",
              response
            )
        );
      }
    },
    [socket, state.initialData.formId, state.steps]
  );

  const handleAutomationClick = useCallback(
    (stepId) => {
      if (!state.initialData.formId) {
        console.error("Form ID is missing.");
        return;
      }

      const apiUrl = STEP_API_MAPPING[stepId];
      if (!apiUrl) return;

      fetch(apiUrl, {
        method: "POST",
        body: JSON.stringify({ formId: state.initialData.formId }),
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      })
        .then((response) => response.json())
        .then((data) =>
          console.log(
            `[IndividualLoanTracker] API call to ${apiUrl} succeeded:`,
            data
          )
        )
        .catch((error) =>
          console.error(
            `[IndividualLoanTracker] Error calling ${apiUrl}:`,
            error
          )
        );
    },
    [state.initialData.formId]
  );

  useEffect(() => {
    console.log(
      "state.initialData.progressStep:",
      state.initialData.progressStep
    );
    if (state.initialData.progressStep) {
      setCurrentStep(state.initialData.progressStep);
    }
  }, [state.initialData.progressStep]);

  const navigateToPage = useCallback(
    (page) => {
      if (!page || !isMounted.current || !isDataReady) return;

      const routes = {
        "pdf-viewer": "/pdf-viewer",
        "custom-pdf-viewer-tables": "/custom-pdf-viewer-tables",
      };

      const route = routes[page];
      if (route) {
        const returnPath = `/individual-loan-tracker?id=${formId}`;
        navigate(route, {
          state: {
            ...(page === "custom-pdf-viewer-tables"
              ? {
                  pdfBase64: state.initialData.pdfBase64NorCalTemplate,
                  jsonData: state.initialData.docAiResultsNorCalTemplate,
                  formId: state.initialData.formId,
                }
              : {
                  pdfBase64: state.initialData.pdfBase64,
                  jsonData: state.initialData.jsonData,
                  iframe: state.initialData.iframe,
                  formId: state.initialData.formId,
                  loanName: state.initialData.loan_name,
                  progressStep: state.initialData.progressStep,
                  pdfBase64NorCalTemplate:
                    state.initialData.pdfBase64NorCalTemplate,
                  docAiResultsNorCalTemplate:
                    state.initialData.docAiResultsNorCalTemplate,
                }),
            returnPath,
          },
          replace: false,
        });
      }
    },
    [navigate, state.initialData, isDataReady, formId]
  );

  const { uploadFile, deleteFile } = useFileUpload(
    state.initialData.formId,
    state.initialData.progressStep,
    (updater) => {
      if (dataFetchedRef.current) {
        const newData = updater(state.initialData);
        dispatch({
          type: "SET_INITIAL_DATA",
          payload: newData,
        });
      }
    },
    socket,
    additionalFiles,
    setAdditionalFiles
  );

  const handleFileUpload = async (file) => {
    if (!file) {
      console.error("No file provided");
      return;
    }

    const response = await uploadFile(file);
    if (response.success && response.data) {
      setAdditionalFiles((prev) => [
        ...prev,
        {
          key: response.data.key,
          url: response.data.url,
          name: response.data.name,
        },
      ]);
    }
  };

  const documentSections = [
    {
      title: "Bank Credit Memo",
      description: "Upload Bank's Credit Memo",
      pdfUrl: pdfUrl1,
      onClick: () => navigateToPage("pdf-viewer"),
      placeholder: "Upload Bank Credit Memo",
      enableDownload: true,
    },
    {
      title: "NorCal Credit Memo",
      description: "Upload NorCal Credit Memo to Generate Documents",
      pdfUrl: pdfUrl2,
      onClick: () => navigateToPage("custom-pdf-viewer-tables"),
      placeholder: "Upload NorCal Credit Memo",
      enableDownload: true,
    },
    {
      title: "Additional Documents",
      description: "Uploaded Additional Documents",
      pdfUrl: null,
      onClick: handleFileUpload,
      placeholder: "Upload document",
      enableDownload: true,
      enableUpload: true,
      additionalFiles: additionalFiles,
      onDeleteFile: deleteFile,
    },
  ];

  return (
    <div className="bg-[#f4f5f5] h-[100vh] flex flex-col">
      <div className="py-1 px-5 flex-1 overflow-y-auto">
        <div className="flex justify-between mb-4 mt-4 ml-8">
          <div className="my-auto">
            <button
              onClick={() => navigate("/dynamic-table")}
              className="flex items-center gap-1 text-primary-default-blue hover:text-primary-dark-blue"
            >
              <img
                src="/images/ArrowLeft.svg"
                width={16}
                height={16}
                alt="Back to Home"
              />{" "}
              Back to home
            </button>
          </div>
        </div>
        <div className="mt-4">
          <h3 className="text-[28px] text-[#363C45] font-[500] mb-6 ml-8">
            {state.initialData.loan_name}
          </h3>
          <div className="grid grid-cols-3 gap-5">
            {documentSections.map((section, index) => (
              <DocumentSection key={index} {...section} />
            ))}
          </div>
        </div>
      </div>
      <div className="bg-white py-1 shadow-[0rem_-0.5rem_1rem_0rem_rgba(0,0,0,0.08)] h-[20%]">
        <StepSlider
          steps={state.steps}
          currentStep={currentStep}
          onStepChange={handleStepChange}
          onAutomationClick={handleAutomationClick}
        />
        <ProgressBar currentStep={currentStep} steps={state.steps} />
      </div>
    </div>
  );
};

export default IndividualLoanTracker;
