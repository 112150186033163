export const ProcessSteps = [
  { id: 0, key: "0", title: "None", completed: true, pending: false },
  {
    id: 1,
    key: "1",
    title: "New Loan. Credit Memo Received!",
    completed: true,
    pending: false,
  },
  {
    id: 2,
    key: "2",
    title: "Double Check Nor-Cal Credit Memo",
    completed: false,
    action: "pdf-viewer",
    pending: false,
  },
  {
    id: 3,
    key: "3",
    title: "Upload Nor-Cal Credit Memo to 2nd Page and Check Info",
    completed: false,
    action: "pdf-viewer",
    pending: false,
  },
  {
    id: 4,
    key: "4",
    title: "Upload documents for CAR Approval",
    completed: false,
    action: "custom-pdf-viewer-tables",
    pending: false,
  },
  {
    id: 5,
    key: "5",
    title: "Waiting for CAR Approval",
    completed: false,
    action: "custom-pdf-viewer-tables",
    pending: true,
  },
  {
    id: 6,
    key: "6",
    title: "CAR Approved, Sent Out Additional Questions, Waiting for Response",
    completed: false,
    action: "custom-pdf-viewer-tables",
    pending: true,
  },
  {
    id: 7,
    key: "7",
    title: "IBank Documents Received from Lender, Generate Invoices",
    completed: false,
    pending: false,
  },
  {
    id: 8,
    key: "8",
    title: "Upload documents for FAR Approval",
    completed: false,
    status: true,
    pending: false,
  },
  {
    id: 9,
    key: "9",
    title: "Waiting for FAR Approval",
    completed: false,
    pending: false,
  },
  {
    id: 10,
    key: "10",
    title: "FAR Approved, Loan Closed!",
    completed: false,
    pending: true,
  },
];
