import { Worker, Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { FileList } from "./upload";
import toast from "react-hot-toast";
import { axiosInstance } from "../../utils/queryClient";

/**
 * Get signed URLs for downloading files from FormCopilot API.
 * @param {string[]} keys - Array of file keys
 * @returns {Promise<Record<string, string>>} - Object with file keys as keys and signed URLs as values
 */
const getSignedUrls = async (keys) => {
  try {
    const { data } = await axiosInstance.post("/files/get_links", { keys });
    if (!data.success)
      throw new Error(data.message || "Failed to get download URLs");
    return data.data;
  } catch (error) {
    console.error("Error getting signed URLs:", error);
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(`Failed to get download URLs: ${errorMessage}`);
    throw error;
  }
};

/**
 * DocumentSection component for displaying and managing PDF documents.
 * Supports both single document and multiple documents view.
 *
 * @param {Object} props - Component properties
 * @param {string|string[]} props.pdfUrl - Single PDF URL or array of PDF URLs
 * @param {string} props.title - Document title
 * @param {string} props.description - Document description text
 * @param {function} props.onClick - Callback function when document area is clicked
 * @param {string} [props.placeholder="Upload document"] - Placeholder text shown when no document is uploaded
 * @param {boolean} [props.enableDownload=false] - Whether to enable download functionality
 * @param {boolean} [props.enableUpload=true] - Whether to enable upload functionality
 * @param {Array} [props.additionalFiles=[]] - Additional files to display in list view
 * @param {function} [props.onDeleteFile] - Callback function when deleting a file from the list
 * @returns {JSX.Element} Document viewer component
 */
export function DocumentSection({
  title,
  description,
  pdfUrl,
  onClick,
  placeholder,
  enableDownload = false,
  enableUpload = true,
  additionalFiles = [],
  onDeleteFile,
}) {
  const isMultipleFiles = Array.isArray(pdfUrl) || additionalFiles.length > 0;
  const hasPdfContent = isMultipleFiles
    ? (Array.isArray(pdfUrl) && pdfUrl.length > 0) || additionalFiles.length > 0
    : !!pdfUrl;

  const handleFileUpload = (e) => {
    e.stopPropagation();
    if (!enableUpload) return;

    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".pdf";
    input.onchange = (e) => {
      const file = e.target.files[0];
      if (file) onClick(file);
    };
    input.click();
  };

  const downloadFile = async (url, filename) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error downloading file:", error);
      toast.error("Failed to download file");
    }
  };

  const handleDownload = async (e) => {
    e.stopPropagation();
    if (!hasPdfContent) return;

    const now = new Date();
    const timestamp = now
      .toLocaleString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
      .replace(/,/g, "")
      .replace(/:/g, "-")
      .replace(/\s/g, "_");

    try {
      if (isMultipleFiles) {
        // Get all files urls
        const keys = additionalFiles.map((file) => file.key);
        const urlsMap = await getSignedUrls(keys);

        // Download all files
        for (const file of additionalFiles) {
          const url = urlsMap[file.key];
          if (url) {
            const filename = `${file.name}_${timestamp}`;
            await downloadFile(url, filename);
          }
        }
      } else if (pdfUrl) {
        // Single file download
        await downloadFile(pdfUrl, `${title}_${timestamp}.pdf`);
      }
    } catch (error) {
      console.error("Error downloading files:", error);
      toast.error("Failed to download files");
    }
  };

  return (
    <div className="flex flex-col gap-2 w-full px-6">
      <div className="pl-2 flex justify-between items-center">
        <div>
          <h2 className="text-[15px] font-medium leading-5 text-gray-900">
            {title}
          </h2>
          <p className="text-[#535C69] text-sm leading-6">{description}</p>
        </div>
        {enableDownload && hasPdfContent && (
          <button
            onClick={handleDownload}
            className="flex items-center gap-1 px-3 py-1.5 text-sm text-primary-default-blue hover:text-primary-dark-blue"
          >
            <img
              src="/images/Download.svg"
              width={16}
              height={16}
              alt="Download"
              className="w-4 h-4"
            />
            Download {isMultipleFiles ? "All" : ""}
          </button>
        )}
      </div>
      {/* 
         For the "second method," we remove all conditional checks 
         and ALWAYS call onClick when the container is clicked.
       */}
      <div
        onClick={onClick}
        className="w-full h-[calc(100vh-300px)] min-h-[400px] border-2 border-gray-200 p-4 text-center relative flex items-center justify-center bg-white rounded-lg mx-2 cursor-pointer hover:border-gray-300 transition-colors"
      >
        {hasPdfContent ? (
          isMultipleFiles ? (
            <div className="w-full h-full overflow-auto">
              {Array.isArray(pdfUrl) && (
                <FileList files={pdfUrl} enableDelete={false} />
              )}
              {additionalFiles.length > 0 && (
                <div className="space-y-2 mt-4">
                  {additionalFiles.map((file) => (
                    <div
                      key={file.key}
                      className="flex items-center justify-between p-2 bg-gray-50 rounded-lg"
                    >
                      <div className="flex items-center gap-2">
                        <svg
                          className="h-5 w-5 text-gray-400"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                          />
                        </svg>
                        <span className="text-sm text-gray-700 truncate">
                          {file.name}
                        </span>
                      </div>
                      {onDeleteFile && (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            onDeleteFile(file.key);
                          }}
                          className="text-red-600 hover:text-red-800 transition flex items-center justify-center p-1 rounded-full hover:bg-red-50"
                        >
                          <img
                            src="/images/Trash.svg"
                            width={16}
                            height={16}
                            alt="Delete"
                            className="w-4 h-4"
                          />
                        </button>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ) : (
            <div className="h-full w-full px-2 overflow-auto">
              <Viewer
                fileUrl={pdfUrl}
                onDocumentLoad={() => console.log("Document loaded")}
                defaultScale={SpecialZoomLevel.PageFit}
              />
            </div>
          )
        ) : enableUpload ? (
          <div className="flex flex-col items-center gap-2 px-2">
            <img
              src="/images/FileArrowUp.svg"
              width={48}
              height={48}
              alt="FileArrowUp"
              className="w-12 h-12"
            />
            <p className="text-primary-default-blue font-medium">
              {placeholder || "Upload document"}
            </p>
          </div>
        ) : (
          <div className="flex flex-col items-center gap-2 px-2">
            <p className="text-gray-400">No document available</p>
          </div>
        )}
      </div>
    </div>
  );
}
